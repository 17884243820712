import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PhotoGrid from "../components/PhotoGrid"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const IndexPage = ({data, pageContext}) => {

  const currentPage = pageContext.currentPage;
  const numPages = pageContext.numPages;
  
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  console.log(pageContext);
  const prevPage = currentPage - 1 === 1 ? `tags/${pageContext.tagSlug}` : `tags/${pageContext.tagSlug}/${(currentPage - 1).toString()}`;
  const nextPage = `tags/${pageContext.tagSlug}/${(currentPage + 1).toString()}`;

  const locations = data.photosByTag.nodes;
  console.log(data);
  const title = `${pageContext.tagName}`;
  return (
    <Layout>
      <SEO title={`Signs tagged '${title}'`} image={data.placeholderImage.childImageSharp.fluid.src} />
      <section>
        <div className="breadcrumbs">
          <Link onClick={e => { trackCustomEvent({ category: "navigation", action: "all-tags", label: title }) }} to='/tags'>All tags</Link> / 
          <Link onClick={e => { trackCustomEvent({ category: "navigation", action: "all-tags", label: title }) }} to={`/tags/${pageContext.tagSlug}`}> {title}</Link>
        </div>
        <h2>Signs tagged <em>{title}</em></h2>
      <PhotoGrid showArrow={false} title={title} photos={locations}></PhotoGrid>
      {!isFirst && (
        <Link className="more-link" to={prevPage} rel="prev">
          ← Later
        </Link>
      )}
      {!isLast && (
        <Link className="more-link" to={nextPage} rel="next">
          Earlier →
        </Link>
      )}
      </section>
    </Layout>
  )
}
  
export default IndexPage

export const pageQuery = graphql`
  query PhotosByTag25($tagSlug: String, $skip: Int!, $limit: Int!) {
    placeholderImage: file(relativePath: { eq: "banner-narrow.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photosByTag: allLocationsJson(sort: {fields: images___date, order: DESC}, limit: $limit, skip: $skip, filter: {images: {elemMatch: {keywords: {elemMatch: {slug: {eq: $tagSlug}}}}}}) {
      nodes {
        lng
        lat
        fields {
          path
          cityFullPath
        }
        sublocation
        city
        state
        country
        images {
          title
          caption
          credit
          src {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      totalCount
    }
  }
`